import { ToastProvider } from '@octano/global-ui';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import AppVersion from './components/version/AppVersion';
import routes from './config/routes';
import CourseSelectionProvider from './providers/CourseSelectionProvider';
import ParametersProvider from './providers/ParametersProvider';
import UserProvider from './providers/UserProvider';

function App() {
  return (
    <UserProvider>
      <ToastProvider>
        <CourseSelectionProvider>
          <ParametersProvider>
            <AppVersion />
            <Router>
              <Switch>
                {Object.entries(routes)?.map(([key, value]) => {
                  const Layout = value.layoutComponent;
                  return (
                    <Route
                      key={key}
                      path={key}
                      render={(props: any) => (
                        <Layout {...props} pathname={key} />
                      )}
                    />
                  );
                })}
                <Redirect from="*" to="/auth" />
              </Switch>
            </Router>
          </ParametersProvider>
        </CourseSelectionProvider>
      </ToastProvider>
    </UserProvider>
  );
}

export default App;
