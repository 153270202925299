import { Alert, Button, TextInput, addToast } from '@octano/global-ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import {
  requestActivationEmail,
  requestAuthUser,
  requestForgotPasswordEmail,
} from '../../api/requests/auth';
import { ReactComponent as AddemicProductLogo } from '../../assets/addemic/logo_expanded.svg';
import AuthContainer from '../../components/container/AuthContainer';
import { IS_ADDEMIC } from '../../config/constants';
import { useUserState } from '../../hooks/useUserState';
import { useValidations } from '../../hooks/useValidations';
import { parseJWT, setJWT } from '../../utils/auth';
import EmailSentModal from './parts/EmailSentModal';
import InvalidScopeModal from './parts/InvalidScopeModal';
import RequestEmailModal from './parts/RequestEmailModal';

type AuthFields = {
  email: string;
  password: string;
};

enum EmailModalType {
  ACTIVATE_ACCOUNT = 'activate',
  FORGOT_PASSWORD = 'recover',
  NONE = 'none',
}

enum SuccessModalType {
  ACTIVATE_EMAIL_SENT = 'activate_email_sent',
  FORGOT_PASSWORD_EMAIL_SENT = 'forgot_email_sent',
  NONE = 'none',
}

const Auth = () => {
  const prefix = 'auth';
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AuthFields>({
    mode: 'onChange',
  });
  const {
    msgValidations,
    validateTextNotEmpty,
    validateEmail,
  } = useValidations();
  const [errorLogin, setErrorLogin] = useState<
    'HTTP_ERROR' | 'CONNECTION' | 'UNAUTHORIZED' | 'INVALID_SCOPE'
  >();
  const { setIsLogged, setUserData } = useUserState();
  const [openedEmailModal, setOpenedEmailModal] = useState<EmailModalType>(
    EmailModalType.NONE,
  );
  const [
    openedSuccessModal,
    setOpenedSuccessModal,
  ] = useState<SuccessModalType>(SuccessModalType.NONE);

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const onLogin = async (values: AuthFields) => {
    if (!values.email || !values.password) {
      setErrorLogin('HTTP_ERROR');
      return;
    }

    const result = await requestAuthUser({
      email: values.email,
      password: values.password,
    });

    if (result.data) {
      const jwt = result.data.access.token;
      setErrorLogin(undefined);
      setJWT(jwt);
      setUserData(parseJWT(jwt)?.account);
      setIsLogged(true);
    } else {
      if (
        result.error.code === 'HTTP_ERROR' &&
        result.error.status === 401 &&
        result.error.data.message === 'inactive'
      ) {
        setErrorLogin('UNAUTHORIZED');
      } else {
        setErrorLogin(result.error.code);
      }

      if (
        result.error.code === 'HTTP_ERROR' &&
        result.error.status === 401 &&
        result.error.data.message === 'Not Scope'
      ) {
        setErrorLogin('INVALID_SCOPE');
      }
    }
  };

  const handleEmailRequestConfirmation = async ({
    email,
  }: {
    email: string;
  }) => {
    if (openedEmailModal === EmailModalType.FORGOT_PASSWORD) {
      setIsSendingEmail(true);
      const res = await requestForgotPasswordEmail(email);
      setIsSendingEmail(false);

      if (res.error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`auth.emailRequestError`),
        });
      } else {
        setOpenedSuccessModal(SuccessModalType.FORGOT_PASSWORD_EMAIL_SENT);
      }
    } else if (openedEmailModal === EmailModalType.ACTIVATE_ACCOUNT) {
      setIsSendingEmail(true);
      const res = await requestActivationEmail(email);
      setIsSendingEmail(false);

      if (res.error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`auth.emailRequestError`),
        });
      } else {
        setOpenedSuccessModal(SuccessModalType.ACTIVATE_EMAIL_SENT);
      }
    }
  };

  const requestEmailModalTexts: {
    [index: string]: { title: string; confirmBtnText: string };
  } = {
    [EmailModalType.FORGOT_PASSWORD]: {
      title: t('auth.recoverPasswordModalTitle'),
      confirmBtnText: t('common.btnRecover'),
    },
    [EmailModalType.ACTIVATE_ACCOUNT]: {
      title: t('auth.activateModalTitle'),
      confirmBtnText: t('common.btnActivate'),
    },
  };

  const getEmailSentDescription = (modalType: SuccessModalType): string => {
    switch (modalType) {
      case SuccessModalType.ACTIVATE_EMAIL_SENT:
        return t('auth.activateEmailSentMsg');
      case SuccessModalType.FORGOT_PASSWORD_EMAIL_SENT:
        return t('auth.recoverPwdEmailSentMsg');
      default:
        return '';
    }
  };

  return (
    <>
      <InvalidScopeModal
        isOpen={errorLogin === 'INVALID_SCOPE'}
        onConfirm={() => setErrorLogin(undefined)}
      />
      <RequestEmailModal
        isOpen={openedEmailModal !== EmailModalType.NONE}
        isLoading={isSendingEmail}
        onConfirm={handleEmailRequestConfirmation}
        onCancel={() => setOpenedEmailModal(EmailModalType.NONE)}
        {...requestEmailModalTexts[openedEmailModal]}
      />
      <EmailSentModal
        isOpen={
          openedSuccessModal === SuccessModalType.FORGOT_PASSWORD_EMAIL_SENT ||
          openedSuccessModal === SuccessModalType.ACTIVATE_EMAIL_SENT
        }
        description={getEmailSentDescription(openedSuccessModal)}
        onConfirm={() => {
          setOpenedSuccessModal(SuccessModalType.NONE);
          setOpenedEmailModal(EmailModalType.NONE);
        }}
      />
      <AuthContainer>
        <Row>
          <Col xs={12} className="text-center">
            {IS_ADDEMIC ? (
              <AddemicProductLogo style={{ maxHeight: 80 }} />
            ) : (
              <img
                src="/tenant/logo_expanded.svg"
                alt="tenant_logo"
                style={{ maxHeight: 100 }}
              />
            )}
          </Col>
          <Col xs={12}>
            <p className="text-center pt-4">
              <Trans t={t}>{t(`${prefix}.welcome`)}</Trans>
            </p>
          </Col>

          <Col xs={12} className="py-0 my-0" style={{ minHeight: '40px' }}>
            {errorLogin && errorLogin !== 'INVALID_SCOPE' && (
              <Alert
                color="danger"
                icon="error"
                noFill
                size="sm"
                text={t(`${prefix}.errors.${errorLogin}`)}
                isOpen={!!errorLogin}
                toggle={() => setErrorLogin(undefined)}
              />
            )}
          </Col>

          <Col xs={12} className="pt-2">
            <Form onSubmit={handleSubmit(onLogin)}>
              <Row>
                <Col xs={12}>
                  <TextInput
                    name="email"
                    type="text"
                    label={t(`${prefix}.labelEmail`)}
                    control={control}
                    rules={{
                      validate: {
                        email: validateEmail,
                        notEmpty: validateTextNotEmpty,
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col xs={12}>
                  <TextInput
                    name="password"
                    type="password"
                    label={t(`${prefix}.labelPassword`)}
                    control={control}
                    rules={{
                      required: msgValidations.required,
                    }}
                  />
                </Col>
              </Row>
              <div className="py-4">
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  text={t(`${prefix}.loginBtn`)}
                  fullwidth
                />
              </div>
            </Form>
          </Col>
          <Col xs={12} className="text-center">
            <button
              className="mb-0 text-center anchor-like "
              onClick={() =>
                setOpenedEmailModal(EmailModalType.FORGOT_PASSWORD)
              }
            >
              {t('auth.forgotPassword')}
            </button>
          </Col>
          <Col xs={12} className="pt-3 pb-2">
            <p className="text-center mb-0">
              {t(`${prefix}.activateDescription`)}
            </p>
          </Col>
          <Col xs={12} className="text-center mb-0">
            <button
              className="mb-0 text-center anchor-like "
              onClick={() =>
                setOpenedEmailModal(EmailModalType.ACTIVATE_ACCOUNT)
              }
            >
              {t(`${prefix}.activateLink`)}
            </button>
          </Col>
        </Row>
      </AuthContainer>
    </>
  );
};

export default Auth;
