import { Box } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import DisplayError from '../../../components/DisplayError';
import { useAcademicInformation } from '../../../components/careerSelector/useAcademicInformation';
import { SectionTeacher } from '../../../types/sectionType';
import { CourseRow } from './CoursesList';
import GoToEnrollmentMsg from './GoToEnrollmentMsg';

export interface Props {
  courses: CourseRow[];
  errorLoading: boolean;
}

export default function CoursesBoxes({ courses, errorLoading }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const { selectedCareer } = useAcademicInformation();

  const professorsReduceFn = (
    professorsList: string,
    prof: SectionTeacher,
    idx: number,
    professorsArr: SectionTeacher[],
  ) => {
    const separator = idx > 0 && idx !== professorsArr.length ? '-' : '';
    return `${professorsList} ${separator} ${prof.account.fullName}`;
  };

  const onBoxClick = (course: CourseRow) => {
    const professorsStr = course.professors.reduce(professorsReduceFn, '');
    history.push(`/student-management/courses/${course.id}`, {
      course: { ...course, professors: professorsStr },
      section: course.id,
    });
  };

  if (errorLoading) {
    return (
      <DisplayError
        title={t('common.displayError.title')}
        textBody={t('common.displayError.errorUnexpected')}
        insideCard
      />
    );
  } else if (courses.length === 0) {
    return (
      <GoToEnrollmentMsg
        title={t(`myCourses.goToEnrrollment.title`)}
        body={t(`myCourses.goToEnrrollment.description`)}
        actionButton={{
          text: t(`myCourses.goToEnrrollment.btnText`),
          action: () =>
            history.push(`/enrollment/${selectedCareer.studyPlanEnrollmentId}`),
        }}
      />
    );
  }

  return (
    <Row>
      {courses.map((course) => (
        <Col
          lg={4}
          md={6}
          sm={12}
          className="mb-4"
          key={course.id}
          onClick={() => onBoxClick(course)}
        >
          <Box
            title={course.name}
            variant="outlined"
            color="secondary"
            body={
              <div className="mt-5 text-light">
                <div>{course.section}</div>

                <div className="font-weight-bold">
                  {t(`myCourses.professors`)}
                </div>
                {course.professors.map((item) => (
                  <div key={item.account.id}>{item.account.fullName}</div>
                ))}

                <span className="font-weight-bold">
                  {t(`myCourses.credits`)}
                </span>
                <span>{course.credits}</span>
              </div>
            }
            className="w-100 box-hover"
          ></Box>
        </Col>
      ))}
    </Row>
  );
}
