import { UserAction, UserActionTypes, UserState } from '../types/userTypes';
import { getJWT } from '../utils/auth';

export const initialState: UserState = {
  isLogged: getJWT() ? true : false,
  isSessionExpired: false,
  userData: undefined,
};

const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionTypes.RESET_STATE:
      return {
        ...initialState,
        isLogged: false,
      };

    case UserActionTypes.SET_IS_LOGGED:
      return {
        ...state,
        isLogged: action.payload,
      };

    case UserActionTypes.SET_IS_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.payload,
      };

    case UserActionTypes.SET_FULL_NAME: {
      const userData = state.userData && {
        ...state.userData,
        fullName: action.payload,
      };

      return {
        ...state,
        userData,
      };
    }

    case UserActionTypes.SET_USER_DATA: {
      const prevUserData = state.userData;
      const userData = prevUserData
        ? { ...prevUserData, ...action.payload }
        : (action.payload as UserState['userData']);

      return {
        ...state,
        userData,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
