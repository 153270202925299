import { Icon, Table } from '@octano/global-ui';
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { SectionScheduleTypeEnum } from '../../../types/sectionType';
import {
  StudentSelectedSections,
  sectionListType,
} from '../../../types/studentManagementTypes';
import sortSectionSchedule from '../../../utils/sortSectionSchedule';

type CoursesInfoProps = {
  coursesInfo?: StudentSelectedSections;
};

const CoursesInfo = ({ coursesInfo }: CoursesInfoProps) => {
  const prefix = 'studentManagement';
  const { t } = useTranslation();
  const [sectionList, setSectionList] = useState<sectionListType[]>([]);
  const [sectionDetail, setSectionDetail] = useState<sectionListType[]>();
  const [columns, setColumns] = useState<ColumnTable<any>[]>([]);
  const [data, setData] = useState<any>(undefined);
  const [hasHeader, setHasHeader] = useState<boolean>(false);

  const sectionsColumns: ColumnTable<sectionListType>[] = useMemo(
    () => [
      {
        columnName: 'sectionName',
        headerText: t(`${prefix}.section`),
      },
      {
        columnName: 'courseName',
        headerText: t(`${prefix}.course`),
      },
      {
        columnName: 'teachers',
        headerText: t(`${prefix}.teacher`),
        cellFormat: ({ row }) =>
          row.teachers.map((name, index) => (
            <div key={index}>
              <span>{name}</span>
              <br />
            </div>
          )),
      },
      {
        columnName: 'sectionName',
        headerText: '',
        cellFormat: ({ row }) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              setSectionDetail([row]);
            }}
          >
            <Icon name="chevron_right" />
          </div>
        ),
      },
    ],
    [t],
  );

  const goBack = useCallback(() => {
    setData(sectionList);
    setColumns(sectionsColumns);
    setHasHeader(false);
  }, [sectionList, sectionsColumns]);

  const sectionDetailsColum: ColumnTable<sectionListType>[] = useMemo(
    () => [
      {
        columnName: 'sectionName',
        headerText: (
          <div onClick={goBack} className="cursor-pointer">
            <Icon name="chevron_left" color="inherit" size={12} />
            <span className="pl-2">{t(`${prefix}.goBack`)}</span>
          </div>
        ),
        cellFormat: ({ row }) => (
          <Row
            key={row.courseName + '-' + row.sectionName}
            className="section-box text-small"
          >
            <Col xs={12} md={3} className="mb-3">
              <span className="fw-700 text-dark">{t(`${prefix}.section`)}</span>
              <br />
              <span>{row.sectionName}</span>
            </Col>
            <Col xs={12} md={3} className="mb-3">
              <span className="fw-700 text-dark">{t(`${prefix}.teacher`)}</span>
              <br />
              <div>
                {row.teachers.map((name) => (
                  <div key={name}>
                    <span>{name}</span>
                    <br />
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={8} md={3} className="mb-3 hours-list">
              <span className="fw-700 text-dark">
                {t(`${prefix}.schedule`)}
              </span>
              <br />
              <div>
                {row.schedules.map((schedule) => (
                  <div key={`${schedule.day}-${schedule.startHour}`}>
                    <span className="mr-2 text-uppercase">{schedule.day}</span>
                    <span>{`${schedule.startHour}-${schedule.endHour}`}</span>
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={4} md={3} className="mb-3 hours-list">
              <span className="fw-700 text-dark">
                {t(`${prefix}.classroom`)}
              </span>
              <br />
              <div>
                {row.schedules.map((schedule) => (
                  <div
                    key={`${schedule.day}-${schedule.startHour}-${schedule.classroom}`}
                  >
                    <span>{schedule.classroom}</span>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        ),
      },
    ],
    [goBack, t],
  );

  useEffect(() => {
    if (coursesInfo) {
      const auxList: sectionListType[] = [];
      coursesInfo.forEach((course) => {
        const schedules = course.sectionData.sectionSchedules
          .filter(
            (schedule) => schedule.type === SectionScheduleTypeEnum.Default,
          )
          .map((schedule) => ({
            day: schedule.day,
            classroom: schedule.classroom?.name || '',
            startHour: schedule.startModule.startTime,
            endHour:
              schedule.endModule?.endTime || schedule.startModule.endTime,
          }));
        auxList.push({
          sectionName: course.sectionData.name,
          courseName: course.course.name,
          teachers: course.sectionData.professors.map(
            (teacher) => teacher.account.fullName,
          ),
          schedules: sortSectionSchedule(schedules),
        });
      });
      setSectionList(auxList);
    }
  }, [coursesInfo]);

  useEffect(() => {
    goBack();
  }, [goBack]);

  useEffect(() => {
    if (sectionDetail) {
      setData(sectionDetail);
      setColumns(sectionDetailsColum);
      setHasHeader(true);
    }
  }, [sectionDetail, setData, setColumns, setHasHeader, sectionDetailsColum]);

  return sectionList.length === 0 ? (
    <></>
  ) : (
    <Row>
      <Col xs={12}>
        <p className="fs-20 text-uppercase fw-700 tc-primary mt-4">
          {t(`${prefix}.myCourses`)}
        </p>
      </Col>
      <Col xs={12} className={hasHeader ? 'course-details' : 'course-list'}>
        <Table
          borderless={false}
          striped={false}
          height={700}
          columns={columns}
          data={data || []}
          subHeader={
            (hasHeader && sectionDetail && (
              <span className="tc-primary">{sectionDetail[0].courseName}</span>
            )) ||
            undefined
          }
        />
      </Col>
    </Row>
  );
};

export default CoursesInfo;
