import { Schedule, ScheduleType } from '@octano/global-ui';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import {
  ModuleType,
  getModulesWithoutAuth,
} from '../../../api/requests/courseModules';
import { requestStudentSectionsInfo } from '../../../api/requests/studentManagement';
import CourseTypeLegends from '../../../components/calendar/CourseTypeLegends';
import { CareerStatus } from '../../../components/careerSelector/academicInformationTypes';
import { useAcademicInformation } from '../../../components/careerSelector/useAcademicInformation';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { SectionScheduleTypeEnum } from '../../../types/sectionType';
import { StudentSelectedSections } from '../../../types/studentManagementTypes';
import sortSectionSchedule from '../../../utils/sortSectionSchedule';

type ScheduleInfoProps = {
  setCoursesInfo: Dispatch<SetStateAction<StudentSelectedSections | undefined>>;
};

const ScheduleInfo = ({ setCoursesInfo }: ScheduleInfoProps) => {
  const prefix = 'studentManagement';
  const { t } = useTranslation();
  const { selectedCareer } = useAcademicInformation();
  const [modules, setModules] = useState<ModuleType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedSections, setSelectedSections] = useState<
    StudentSelectedSections | undefined
  >();

  const getModules = useCallback(async () => {
    const { data } = await getModulesWithoutAuth();
    if (data) {
      setModules(data.data);
    } else {
      setModules([]);
      setIsError(true);
      setErrorMessage('CONNECTION');
    }
  }, [setModules, setIsError, setErrorMessage]);

  const getStudentSectionsInfo = useCallback(async () => {
    setIsLoading(true);
    await getModules();
    const { data } = await requestStudentSectionsInfo(
      selectedCareer.studyPlanEnrollmentId,
    );
    if (data) {
      setSelectedSections(data);
    } else {
      setSelectedSections(undefined);
      setIsError(true);
      setErrorMessage('CONNECTION');
    }
    setIsLoading(false);
  }, [getModules, selectedCareer.studyPlanEnrollmentId]);

  useEffect(() => {
    getStudentSectionsInfo();
  }, [getStudentSectionsInfo]);

  useEffect(() => {
    if (selectedSections && selectedSections.length === 0) {
      setIsError(true);
      setErrorMessage(t(`${prefix}.noSectionsMessage`));
    }
    setCoursesInfo(selectedSections);
  }, [selectedSections, setCoursesInfo, setIsError, setErrorMessage, t]);

  const schedules = useMemo(() => {
    let results: ScheduleType[] = [];

    // Se crea array con todos los horarios de las secciones seleccionadas
    if (selectedSections) {
      selectedSections.forEach(({ sectionData, course }) => {
        sortSectionSchedule(sectionData.sectionSchedules).forEach(
          (schedule) => {
            if (schedule.type !== SectionScheduleTypeEnum.Default) {
              return;
            }
            results.push({
              sectionId: sectionData.id,
              scheduleId: schedule.id,
              courseCode: sectionData.name,
              sectionName: course.shortening,
              day: schedule.day,
              activityId: schedule.activity.id,
              moduleStartId: schedule.startModule.id,
              moduleEndId: schedule.endModule
                ? schedule.endModule.id
                : schedule.startModule.id,
              tooltip: {
                courseName: course.name,
                sectionTitle: '',
                sectionName: sectionData.name,
                teachers: sectionData.professors.map(
                  (professor) =>
                    professor.account.fullName ||
                    professor.account.name +
                      ' ' +
                      professor.account.paternalLastName ||
                    '',
                ),
                teachersTitle: t(`${prefix}.teacher`),
                classroom: schedule.classroom?.name || '',
              },
            });
          },
        );
      });
    }
    return results;
  }, [selectedSections, t]);

  if (isLoading) {
    return <Loading />;
  }

  if (selectedCareer.status !== CareerStatus.Regular) {
    return (
      <DisplayError
        insideCard
        loadingAction={isLoading}
        title={t('common.careerSelector.error.title')}
        body={t('common.careerSelector.error.body')}
      />
    );
  }

  if (isError) {
    return (
      <DisplayError
        insideCard
        loadingAction={isLoading}
        title={errorMessage}
        body=""
      />
    );
  }
  return (
    <Row>
      <Col xs={12}>
        <p className="fs-20 text-uppercase fw-700 tc-primary mt-4">
          {t(`${prefix}.mySchedule`)}
        </p>
        <p className="fs-18 text-light mb-5">
          {t(`${prefix}.myScheduleDescription`)}
        </p>
      </Col>
      <Col md={{ size: 10, offset: 1 }}>
        <Schedule
          schedules={schedules}
          modules={modules}
          mobileNavigation={true}
        />
      </Col>
      <Col md={{ size: 10, offset: 1 }} className="mt-5">
        <CourseTypeLegends />
      </Col>
    </Row>
  );
};

export default ScheduleInfo;
