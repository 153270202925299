import { JWT_KEY } from '../config/constants';

/**
 * Función para guardar el token del usuario
 * @param token
 */
export const setJWT = (token: string) => localStorage.setItem(JWT_KEY, token);

/**
 * Función para obtener el token del usuario
 */
export const getJWT = () => localStorage.getItem(JWT_KEY);

/**
 * Función para remover el token del usuario
 */
export const removeJWT = () => localStorage.clear();

/**
 * Función para obtener la data del JWT
 */
export const parseJWT = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
