import { Button } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import {
  SectionScheduleTypeEnum,
  SectionType,
} from '../../../types/sectionType';
import { formatClassSchedule } from '../../../utils/time';

const SectionBox = ({
  options,
}: {
  options: CellFormatOptions<SectionType>;
}) => {
  const prefix = 'sectionList';
  const { t } = useTranslation();
  const { row } = options;
  const {
    selectedCourse,
    selectedSections,
    addSection,
  } = useCourseSelectionState();

  const selectSection = (section: SectionType) => {
    if (selectedCourse) {
      addSection({
        ...section,
        course: selectedCourse,
      });
    }
  };

  return (
    <Row className="section-box">
      <Col xs={12} md={6} className="h-100">
        <Row className="fs-16">
          <Col xs={6} className="fw-700 text-dark">
            {t(`${prefix}.section`)}
          </Col>
          <Col xs={6} className="fw-700 text-dark">
            {t(`${prefix}.slots`)}
          </Col>
          <Col xs={6} className="text-medium text-uppercase">
            {row.name}
          </Col>
          <Col xs={6} className="text-medium">
            {row.slots}
          </Col>
          <Col xs={12} className="pt-2 fw-700 text-dark">
            {t(`${prefix}.teacher`)}
          </Col>
          {row.professors.map((teacher) => (
            <Col
              key={`teacher_${row.id}_${teacher.id}`}
              xs={12}
              className="text-medium text-overflow-ellipsis"
            >
              <span
                className="text-uppercase"
                title={teacher.account.fullName.toUpperCase()}
              >
                {teacher.account.fullName}
              </span>
            </Col>
          ))}
        </Row>
      </Col>
      <Col
        xs={12}
        md={6}
        className="d-flex flex-column justify-content-between fs-16 h-100 py-2 py-md-0"
        style={{ minHeight: '160px' }}
      >
        <div>
          <Row>
            <Col xs={6} className="fw-700 text-dark">
              {t(`${prefix}.schedule`)}
            </Col>
            <Col xs={6} className="fw-700 text-dark">
              {t(`${prefix}.classroom`)}
            </Col>
          </Row>
          {options.row.sectionSchedules
            .filter(
              (schedule) => schedule.type === SectionScheduleTypeEnum.Default,
            )
            .map((schedule, i) => {
              return (
                <Row
                  key={`schedule_${options.row.id}_${schedule.id}_${i}`}
                  className="text-medium"
                >
                  <Col xs={6} className="text-uppercase">
                    {schedule.day}{' '}
                    {formatClassSchedule(
                      schedule.startModule,
                      schedule.endModule,
                    )}
                  </Col>
                  <Col xs={6} className="text-uppercase">
                    {schedule.classroom.name}
                  </Col>
                </Row>
              );
            })}
        </div>
        <div className="py-3">
          <Button
            text={t(`${prefix}.selectBtn`)}
            size="sm"
            onClick={() => selectSection(options.row)}
            disabled={
              options.row.slots <= 0 ||
              selectedSections.some((section) => section.id === options.row.id)
            }
            fullwidth
          />
        </div>
      </Col>
    </Row>
  );
};

export default SectionBox;
